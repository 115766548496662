import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { authenticationSuccessEventService, userService } from '../../_services'
import moment from 'moment'

function AuthenticationSuccessEvent() {

	const [events, setEvents] = useState([]);
	const [users, setUsers] = useState([]);

    useEffect(() => {
		authenticationSuccessEventService.getLastAuthenticationSuccessEvent().then(data => {
			setEvents(data)
        })
        userService.getAll().then(data => {
            setUsers(data)
        })
    }, [])

    const getUsername = (userId) => {
        var user = users.find(user => user.id == userId)
        if (user) {
            return user.username
        }
        return '-'
    }

    const getCustomerName = (userId) => {
        var user = users.find(user => user.id === userId)
        if (user) {
            return user.customerName
        }
        return '-'
    }

	return (
		<Row>
			<Col md={{ span: 8, offset: 2 }}>
				<table className="table">
					<thead>
						<tr>
							<th>
								Användarnamn
							</th>
							<th>
								Kund
							</th>
							<th>
								Senast använd
							</th>
							<th>
								Tidsskillnad
							</th>
						</tr>
					</thead>
					<tbody>
						{ events.map(event => {
							return <tr key={event.id}>
								<td>
									{getUsername(event.id)}
								</td>
								<td>
									{getCustomerName(event.id)}
								</td>
								<td>
									{moment(event.date).format('yyyy-MM-DD HH:mm:ss')}
								</td>
								<td>
									{moment(event.date).toNow(true)}
								</td>
							</tr>
						})}
					</tbody>
				</table>
			</Col>
        </Row>
    )
}

export default AuthenticationSuccessEvent;