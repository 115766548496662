import api from './api.service'

export const domainChangeLogService = {
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/domainChangeLogs'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 1000, sort: 'dateCreated', order: 'desc'})
}

function save(domainChangeLog) {
    return api.post(BASE_URL, domainChangeLog)
}

function update(id, domainChangeLog) {
    return api.put(BASE_URL + "/" + id, domainChangeLog)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
