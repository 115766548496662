import React, { useEffect, useState } from 'react'
import { resultService } from '../../_services/'
import { useTranslation } from "react-i18next";
import { HorizontalBar } from 'react-chartjs-2';
import { Row, Col } from "react-bootstrap";
import moment from 'moment'
import 'moment/locale/sv'

function CounterChart({sensor, startDate, endDate, resolution}) {

	const [chartData, setChartData] = useState({})
	const [maxX, setMaxX] = useState(0)
    const {t} = useTranslation('common');

    useEffect(() => {
        console.log('useEffect')

        moment.locale('sv')

        resultService.getAggregatedResultsForSensors({
            startDate: startDate,
            endDate: endDate,
            resolution: resolution,
            sensors: [sensor.id],
            valueCalculation: 'SUM',
            resultTypes: ['counter_1', 'counter_2']
        }).then(data => {
            console.log(data)

			var max = 0
	        const datasets = []
	        if (data && data['counter_1'] && data['counter_1'][sensor.id]) {
	            var data1 = data['counter_1'][sensor.id]
				data1.forEach((point => {
					var tmp = point.y
					point.y = point.x
					point.x = -tmp
					max = Math.max(max, tmp)
				}))
		        datasets.push({
		            label: 'Counter 1',
		            data: data1,
		            barThickness: 10,
		            backgroundColor: 'blue'
		        })
	        }
	        if (data && data['counter_2'] && data['counter_2'][sensor.id]) {
	            var data2 = data['counter_2'][sensor.id]
		        data2.forEach((point => {
					var tmp = point.y
					point.y = point.x
					point.x = tmp
					max = Math.max(max, tmp)
				}))
				datasets.push({
		            label: 'Counter 2',
		            data: data2,
		            barThickness: 10,
		            backgroundColor: 'red'
		        })
	        }
	        console.log(max)
	        if (max % 5 != 0) {
	            max = max + 5 - (max % 5)
	        }
	        setMaxX(max)
			setChartData({datasets: datasets})
        })
    }, [sensor, startDate, endDate, resolution]);

    const options = {
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                    min: -maxX,
                    max: maxX,
                    callback: function(value, index, ticks) {
                        return Math.abs(value);
                    }
                },
                tooltips: {
                    callback: function(value, index, ticks) {
                        return Math.abs(value);
                    }
                }
            }],
            yAxes: [{
                type: 'time',
                time: {
                    isoWeekday: true,
                    tooltipFormat: 'YYYY-MM-DD HH:mm',
                    displayFormats: {
                        millisecond: 'HH:mm:ss.SSS',
                        second: 'HH:mm:ss',
                        minute: 'HH:mm',
                        hour: 'HH:mm'
                    }
                },
                ticks: {
                    major: {
                        enabled: true
                    }
                },
                stacked: true
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += Math.abs(tooltipItem.xLabel);
					return label
		        }
            }
        }
    }

	return (
		<HorizontalBar options={options} data={chartData} height={600} />
	)
}

export default CounterChart;