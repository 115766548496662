import React from 'react';
import CrudTable from '../table/crudtable'
import { domainChangeLogService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function DomainChangeLog() {

    const {t} = useTranslation('common');

	const initialState = {
		customer: -1,
		username: '',
		password: '',
		enabled: true,
		accountExpired: false,
		accountLocked: false,
		passwordExpired: false,
		userRoles: []
	}

	const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const formatUser = function(cell, row) {
		if (row.user) {
			return row.user.username + " (" + cell + ")"
		}
		return cell
	}

	const formatClass = function(cell) {
		return <span title={cell}>{cell.substring(cell.lastIndexOf('.') + 1)}</span>
	}

	const formatParams = function(cell) {
		if (cell) {
			var keys = Object.keys(cell)
			if (keys && keys.length > 0) {
				var result = []
		        keys.forEach((key, index) => {
		            result.push(key + ": " + cell[key])
		        })
		        return result.join(", ")
			}
	    }
	    return '-'
	}

	const columns = [
		{
		    dataField: 'dateCreated',
		    text: t('domainchangelog.columns.datecreated'),
            formatter: formatDate,
            sortValue: (cell, row) => cell + "_" + ((row.id % 1000) + 1000),
            style: {whiteSpace: 'nowrap'},
			sort: true
		},
		{
			dataField: 'userId',
			text: t('domainchangelog.columns.username'),
			formatter: formatUser,
			sort: true
		},
        {
            dataField: 'type',
            text: t('domainchangelog.columns.type'),
            classes: (cell, row, rowIndex, colIndex) => {
                if (cell === 'CREATE') {
                    return 'table-success'
                } else if (cell === 'UPDATE') {
                    return 'table-warning'
                } else if (cell === 'DELETE') {
                    return 'table-danger'
                }
            },
            sort: true
        },
        {
            dataField: 'objectClass',
            text: t('domainchangelog.columns.class'),
            formatter: formatClass,
            sort: true
        },
		{
			dataField: 'objectId',
			text: t('domainchangelog.columns.id'),
			sort: true
		},
		{
			dataField: 'params',
			text: t('domainchangelog.columns.params'),
            formatter: formatParams,
            filterValue: formatParams,
            style: {whiteSpace: 'nowrap'},
			sort: true
		}
	]

	return (
		<div>
		  <h2>
		    {t('domainchangelog.label')}
		  </h2>
		  <CrudTable
		    service={domainChangeLogService}
		    columns={columns}
		    initialState={initialState}
		    remote={{"filter": false, "search": false, "pagination": false}}
		    creatable={false}
		    editable={false}
		    deletable={true}
		    sort={{dataField: 'dateCreated', order: 'desc'}}
		  />
		</div>
	)
}

export default DomainChangeLog;